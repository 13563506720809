import { render, staticRenderFns } from "./UICreatePortfolioModalv2.vue?vue&type=template&id=065dfd12&scoped=true"
import script from "./UICreatePortfolioModalv2.vue?vue&type=script&lang=js"
export * from "./UICreatePortfolioModalv2.vue?vue&type=script&lang=js"
import style0 from "./UICreatePortfolioModalv2.vue?vue&type=style&index=0&id=065dfd12&prod&lang=scss"
import style1 from "./UICreatePortfolioModalv2.vue?vue&type=style&index=1&id=065dfd12&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065dfd12",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default,InfoIcon: require('/skade-pwa/components/ui/atom/InfoIcon.vue').default,PortfolioPrivacyTag: require('/skade-pwa/components/ui/atom/PortfolioPrivacyTag.vue').default,Modal: require('/skade-pwa/components/ui/atom/Modal.vue').default})
