
import Modal from '../molecule/Modal.vue';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import AddIcon from '~/components/ui/icons/AddIcon.vue';
import InfoIcon from '~/components/ui/icons/InfoIcon.vue';
import ChevronIcon from '~/components/ui/icons/ChevronIcon.vue';
import debounce from 'debounce-async';
import {
  ApiApplicationErrorCodes,
  getApplicationError,
} from '@/utils/behavior/apiErrors';
import { portfolioUniqueNameError } from '~/utils/behavior/portfolio-creation';
//import WButton from '~/components/ui/atom/WButton/WButton.vue';

export default {
  name: 'UICreatePortfolioModalv2',
  components: {
    CloseIcon,
    AddIcon,
    InfoIcon,
    ChevronIcon,
    Modal,
    //WButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledReason: {
      type: String,
      default: null,
    },
  },
  emits: ['onTrade', 'onClose', 'onSearch', 'onSubmit'],
  data() {
    return {
      name: '',
      error: '',
      portfolioType: 'classic',
      selectedProfiles: [],
      currencies: [],
      todayDayOfWeek: this.getDayOfWeek(),
      formValues: {
        name: '',
        date: '',
        privacy: 'only me',
        currency: '',
        type: 'classic',
        cashAmounts: [
          {
            id: 1,
            currencyCode: '',
            amount: '',
          },
        ],
      },
      debouncedValidatePortfolioName: debounce(this.validatePortfolioName, 500),
      portfolioNameValiadtionMessages: {
        required: this.$t('name_required'),
        max: this.$t('name_max_length', { max: 16 }),
        'back-end': this.$t('error_occured_message'),
      },
      showMoreInfo: false,
    };
  },
  computed: {
    /**
     * This function should return the date before which the user must select their portfolio creation date. I.e. it returns the max creation date, exclusive.
     *
     * @returns {string}
     */
    maxCreationDate() {
      // since this function should return the max creation date, exclusive (i.e. the returned date shouldn't be allowed, but only the days earlier), we need to add 1
      return this.maxInclusiveCreationDate(this.getDayOfWeek())
        .add(1, 'day')
        .format('YYYY-MM-DD');
    },
    initialDate() {
      const today = this.$dayjs().$d.getDay();
      // getDay() returns 0-6 (SUNDAY IS 0!!!)
      let toSubstract = 1;
      if (today === 1) {
        toSubstract = 3;
      } else if (today === 0) {
        toSubstract = 2;
      }
      return this.$dayjs().subtract(toSubstract, 'day').format('YYYY-MM-DD');
    },
  },
  created() {
    this.formValues.date = this.initialDate;
  },
  beforeMount() {
    this.currencies = this.$display.money.getAvailableCurrencyNames();
    this.formValues.cashAmounts[0].currencyCode = this.currencies[0];
  },
  methods: {
    submit() {
      if (this.disabled) return;
      const payload = {
        ...this.formValues,
      };
      this.$emit('onSubmit', payload);
    },
    addMoreCash() {
      this.formValues.cashAmounts.push({
        id: this.formValues.cashAmounts.length + 1,
        currencyCode: '',
        amount: '',
      });
    },
    getNonSelectedCurrencies(clickedValue = null) {
      const nonSelected = [...this.currencies];
      this.formValues.cashAmounts.forEach((co) => {
        const index = nonSelected.indexOf(co.currencyCode);
        if (index >= 0 && clickedValue !== co.currencyCode) {
          nonSelected.splice(index, 1);
        }
      });
      return nonSelected;
    },
    onClose(index) {
      this.formValues.cashAmounts.splice(index, 1);
    },
    getDateFormat(date) {
      const d = new Date(date);
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    },
    async validatePortfolioName(name) {
      try {
        await this.$api.validatePortfolioName(name);
        return true;
      } catch (e) {
        const apiError = getApplicationError(e.errorCode);

        console.error('e', e);

        // expand this switch statement as needed
        switch (apiError) {
          // if the profile has another portfolio with the same name, show the error message
          case ApiApplicationErrorCodes.BadRequest.UniquePortfolioName:
            this.portfolioNameValiadtionMessages['back-end'] = this.$t(
              portfolioUniqueNameError
            );
            return false;
          // since we have the length validation in the front-end, we don't need to handle that error type here, so return true
          case ApiApplicationErrorCodes.BadRequest
            .PortfolioNameExceedsMaximumCharacters:
            return true;
          // if there is an error that we have not handled, we can show a generic error message
          default:
            this.portfolioNameValiadtionMessages['back-end'] = this.$t(
              'error_occured_message'
            );
            return false;
        }
      }
    },
    /**
     * The values of `today` are in the range of 0-6, where 0 is Sunday and 6 is Saturday
     *
     * @returns {number}
     */
    getDayOfWeek() {
      return this.$dayjs().$d.getDay();
    },
    /**
     * Determines the maximmum allowed/inclusive creation date for the portfolio.
     *
     * @param {string} today The day of the week, 0 through 6, where 0 is Sunday and 6 is Saturday, for which to return the maximum creation date.
     *
     * @returns {object} The maximum allowed/inclusive creation date for the portfolio.
     */
    maxInclusiveCreationDate(today) {
      const daysToSubtract =
        // if today is sunday, allow friday. that requires us to subtract 2 days
        today === 0
          ? 2
          : // if today is monday, allow friday. that requires us to subtract 3 days
          today === 1
          ? 3
          : // if today is any other day of the week, i.e. tuesday through saturday, the previous day is allowed. that requires us to subtract 1 day
            1;

      // from the current date, subtract the number of days needed
      return this.$dayjs().subtract(daysToSubtract, 'day');
    },
    /**
     * Returns the default portfolio creation date.
     * @returns {string}
     */
    getDefaultCreationDate() {
      return this.maxInclusiveCreationDate(this.getDayOfWeek()).format(
        'YYYY-MM-DD'
      );
    },
    toggleShowMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
    },
  },
};
