import { showErrorToast } from '~/utils/toast-notifications';

export default {
  INIT: (state) => {
    state.messages = [];
  },
  SET_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },
  SET_ERROR: (state, err) => {
    state.error = err;
    showErrorToast(window.$nuxt.$t('error_loading_messages'));
  },
  ADD_MESSAGE(state, payload) {
    payload.participantIds.sort();
    state.conversations.conversations.forEach((c) => {
      c.conversation.participantIds.sort();
    });

    const existingConversation = state.conversations.conversations.find(
      (x) =>
        JSON.stringify(x.conversation.participantIds) ===
        JSON.stringify(payload.participantIds)
    );

    if (existingConversation) {
      if (!Array.isArray(existingConversation.messages)) {
        existingConversation.messages = [];
      }
      existingConversation.conversation.updatedDateUtc = new Date().getTime();
      existingConversation.messages.push(payload);
    } else {
      const convo = {
        conversation: {
          senderUserId: payload.senderUserId,
          participantIds: payload.participantIds,
          id: payload.conversationId,
          updatedDateUtc: payload.updatedDateUtc,
          readBy: [],
        },
        messages: [],
      };

      convo.messages.push(payload);
      state.conversations.conversations.push(convo);
    }

    state.conversations.conversations.sort(
      (a, b) =>
        new Date(b.conversation.updatedDateUtc) -
        new Date(a.conversation.updatedDateUtc)
    );
  },
  ADD_CONVERSATIONS(state, payload) {
    payload.conversations.forEach((convo) => {
      if (!Array.isArray(convo.messages)) {
        convo.messages = [];
      }
    });

    payload.conversations.sort(
      (a, b) =>
        new Date(b.conversation.updatedDateUtc) -
        new Date(a.conversation.updatedDateUtc)
    );

    state.conversations = payload;
  },
  UPDATE_CONVERSATIONS(state, payload) {
    payload.conversations.forEach((convo) => {
      if (!Array.isArray(convo.messages)) {
        convo.messages = [];
      }
    });

    payload.conversations.sort(
      (a, b) =>
        new Date(b.conversation.updatedDateUtc) -
        new Date(a.conversation.updatedDateUtc)
    );

    state.conversations.conversations.forEach((c) => {
      const matchingPayloadConversation = payload.conversations.find(
        (cc) => cc.conversation.id === c.conversation.id
      );

      if (matchingPayloadConversation) {
        const newMessages = matchingPayloadConversation.messages.filter(
          (msg) => !c.messages.some((existingMsg) => existingMsg.id === msg.id)
        );

        c.messages = [...newMessages, ...c.messages];
      }
    });
  },

  ADD_USERS: (state, payload) => {
    state.users = payload;
  },
  SET_ONLINE_STATUS: (state, payload) => {
    state.onlineUsers = payload;
  },
  REMOVE_REACTION: (state, payload) => {
    state.conversations.conversations.forEach((c) => {
      const index = c.messages.findIndex((m) => m.id === payload.id);
      if (index !== -1) {
        c.messages.splice(index, 1);
      }
    });
  },
  SET_MESSAGE_NOTIFICATIONS: (state, payload) => {
    state.messageNotifications = payload;
  },
  ADD_NEW_MESSAGE_NOTIFICATION: (state, payload) => {
    state.messageNotifications.push(payload);
  },
  ADD_MESSAGES_WITH_REPLIES: (state, payload) => {
    state.messagesWithReplies.push(...payload);
  },
  SET_UNREAD_MESSAGE_NOTIFICATION_COUNT: (state) => {
    const unreadNotifications = state.messageNotifications.filter(
      (n) => !n.read
    );
    state.messageNotificationCount = unreadNotifications.length;
  },
  MARK_AS_READ_MESSAGE_NOTIFICATIONS: (state, payload) => {
    const messageNotificationsForConversation =
      state.messageNotifications.filter(
        (n) => n.conversationId === payload.conversationId
      );

    messageNotificationsForConversation.forEach((n) => {
      if (!n.read) {
        n.read = true;
      }
    });
  },
  REMOVE_NOTIFICATION: (state, payload) => {
    const index = state.messageNotifications.findIndex(
      (n) => n.messageId === payload.id
    );
    if (index !== -1) {
      state.messageNotifications.splice(index, 1);
    }
  },
};
